import { Input, Select } from "antd";
import { useHistory } from "react-router";
import "./index.css";
import { useState } from "react";
import { useSearchParams } from "@/hooks/common";
import { stringify } from "qs";

const { Search } = Input;

const SearchSelect = (params) => {
    const { type = 0, onChange } = params;
  
    return (
      <Select onChange={onChange} style={{ width: 118 }} defaultValue={type}>
        <Select.Option key={0} value={0}>
          全文检索
        </Select.Option>
        <Select.Option key={1} value={1}>
          章节检索
        </Select.Option>
        <Select.Option key={2} value={2}>
          图书检索
        </Select.Option>
      </Select>
    );
  };

const SearchKeyBox = () => {
  const history = useHistory();
  const { type = 0, key } = useSearchParams();

  const [state, setState] = useState({ type: type, key })

  const setType = value => setState(x => ({ ...x, type: value }));
  const setKey = e => setState(x => ({ ...x, key: e.target.value }));

  const handleSearch = () => {
      history.push({
          pathname: "/search/results",
          search: `?` + stringify(state)
      })
  };

  return (
    <div className="search">
      <div className="con-box">
        <h4>关学文库专题知识库</h4>
        <div className="ser-box">
          <Search
            placeholder=""
            allowClear
            enterButton="搜索"
            size="large"
            onChange={setKey}
            defaultValue={key}
            onSearch={handleSearch}
            addonBefore={<SearchSelect type={parseInt(type)} onChange={setType}/>}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchKeyBox;
