import { useEffect, useState } from "react";
import { Row, Col, List, Card, Image } from "antd";
import { Link } from "react-router-dom";
import { HOMEPAGE_TOPPEOPLES_CATEGORY } from "@/consts";
import { people } from "@/services";
import { PeopleCoverImage } from "../../../../components/BookImage";

const { Meta } = Card;

const PeopleTwoLineBox = () => {
  const [C1, C2] = HOMEPAGE_TOPPEOPLES_CATEGORY;
  const initialState = {
    loading: true,
    resultMap: HOMEPAGE_TOPPEOPLES_CATEGORY.reduce((prev, curr) => {
      prev[curr] = [];
      return prev;
    }, {}),
  };
  const [results, setResults] = useState(initialState);
  const { loading, resultMap = {} } = results;

  const items1 = resultMap[C1];
  const items2 = resultMap[C2];

  useEffect(() => {
    setResults((x) => ({ ...x, loading: true }));
    const categories = HOMEPAGE_TOPPEOPLES_CATEGORY.map((n, i) => ({
      category: n,
    }));
    people
      .getTopPeoples({
        categories,
      })
      .then(({ result = {} }) => {
        setResults((x) => ({ ...x, resultMap: result.values }));
      })
      .finally(() => {
        setResults((x) => ({ ...x, loading: false }));
      });
  }, []);

  return (
    <div className="con-item figure">
      {items1.length && (
        <div className="con-box">
          <h3>
            <span>关学创立者</span>
            <Link to={`/peoples/${encodeURIComponent(C1)}`}>
              关学思想代表人物 查看更多 &gt;
            </Link>
          </h3>
          <List
            itemLayout="vertical"
            size="large"
            dataSource={items1}
            renderItem={(item) => (
              <List.Item
                key={item.realName}
                extra={
                  <PeopleCoverImage
                    style={{ width: 180, height: 180 }}
                    alt={item.realName}
                    preview={false}
                    src={item.avatorId}
                  />
                }
              >
                <List.Item.Meta
                  title={
                    <>
                      {item.realName}
                      {item.posts && <span>（{item.posts}）</span>}
                    </>
                  }
                />
                <div
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
              </List.Item>
            )}
          />
        </div>
      )}
      {items2.length && (
        <div className="con-box">
          <h3>
            <span>关学研究者</span>
            <Link to={`/peoples/${encodeURIComponent(C2)}`}>
              关学研究学者 查看更多 &gt;
            </Link>
          </h3>
          <List
            itemLayout="vertical"
            size="large"
            dataSource={items2}
            renderItem={(item) => (
              <List.Item
                key={item.title}
                extra={
                  <PeopleCoverImage
                    style={{ width: 180, height: 180 }}
                    alt={item.realName}
                    preview={false}
                    src={item.avatorId}
                  />
                }
              >
                <List.Item.Meta
                  title={
                    <>
                      {item.realName}
                      {item.posts && <span>（{item.posts}）</span>}
                    </>
                  }
                />
                <div
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default PeopleTwoLineBox;
