import axios from "axios";

import { message } from "antd";

const client = axios.create({ baseURL: "/api/services/app/" });

const handleCodeError = (code, error) => {
    const  { history } = error.config;

  switch (code) {
    case 5000:
      message.warning("未发现您的订阅，如未订阅，请先购买订阅");
      break;
    case 5001:
      message.warning("订阅已失效/过期，具体信息请查看个人中心-我的订阅");
      break;
    case 5002:
      if(history) {
        history.push({
            pathname: "/login",
            search: `?returnUrl=${history.location.pathname}`
        });
      }else {
        message.warning("请重新登录");
      }
      break;
    case 6001:
      message.warning("未找到对应数据");
      break;
    case 6002:
      message.warning("当前数据未公开");
      break;
    default:
      break;
  }
};

client.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
      // eslint-disable-next-line no-undef
    const response = error.response;
    if (response.data && response.data.__abp) {
      if (response.data.error) {
        const {
          code,
          message: msg,
          validationErrors = [],
        } = response.data.error;
        if (code) {
          handleCodeError(code, error);
        } else {
          if (validationErrors && validationErrors.length) {
            message.error(validationErrors.map((e) => e.message).join("\r\n"));
          } else if (message) {
            message.error(msg);
          }
        }
      }
    }

    return Promise.reject({ error });
  }
);

export default client;
