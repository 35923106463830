import axios from "../../request";

const GET_TOP_BOOKS = 'book/gettopbooks';
const GET_BOOKS = 'book/getbooks'
const GET_SIMPLE_BOOKS = 'book/getsimplebooks'
const GET_CHAPTER_DETAIL = 'book/getchapterdetail';
const GET_BOOK_INFO_DETAIL = 'book/getbookinfodetail';
const GET_BOOK_CATALOG = 'book/getbookcatalog';
const GET_CHAPTER_FOREDIT = 'book/getchapterforedit';
const SAVE_CHAPTER_FOREDIT = 'book/savechapterforedit';



export const getTopBooks = (params = {}) => axios.post(GET_TOP_BOOKS, params);
export const getBooks = (params = {}) => axios.post(GET_BOOKS, params);
export const getSimpleBooks = (params = {}) => axios.post(GET_SIMPLE_BOOKS, params);
export const getChapterDetail = (params = {}, config = {}) => axios.post(GET_CHAPTER_DETAIL, params, config);
export const getBookInfoDetail = (params = {}) => axios.post(GET_BOOK_INFO_DETAIL, params);
export const getBookCatalog = (params = {}) => axios.post(GET_BOOK_CATALOG, params);


export const getChapterForEdit = (params = {}) => axios.post(GET_CHAPTER_FOREDIT, params);
export const saveChapterForEdit = (params = {}) => axios.post(SAVE_CHAPTER_FOREDIT, params);