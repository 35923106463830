import axios from "../../request";

const GET_RESOURCES = 'search/getresources';
const GET_BOOK_RESOURCES = 'search/getbookresources';
const GET_ARTICLE_RESOURCES = 'search/getarticleresources';
const GET_REALTE_BOOKS = 'search/getrelatebooks';
const GET_BOOKCATALOG_WITHKEY = 'search/GetBookCatalogWithKey';

export const getResources = (params) => axios.post(GET_RESOURCES, params);
export const getBookResources = (params) => axios.post(GET_BOOK_RESOURCES, params);
export const getArticleResources = (params) => axios.post(GET_ARTICLE_RESOURCES, params);
export const getRelateBooks =  (params) => axios.post(GET_REALTE_BOOKS, params);
export const getBookCatalogWithKey =  (params) => axios.post(GET_BOOKCATALOG_WITHKEY, params);