import { Form, Input, Row, Col, Button, Checkbox } from "antd";
import { account } from "@/services";
import "./index.css";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { loginSuccess, loginStart, loginStop } from "@/features/account";
import { useCurrentAccount } from "@/hooks/account";
import { useSearchParams } from "@/hooks/common";

/*
 * 登录页
 */
const LoginPage = () => {
  const history = useHistory();
  const { returnUrl } = useSearchParams();
  const dispatch = useDispatch();
  const [random, setRandom] = useState(new Date().getMilliseconds());
  const { logining } = useCurrentAccount();
  const { state: { redirect = "/" } = {} } = useLocation();

  const updateRandom = () => setRandom(new Date().getMilliseconds());

  const handleFinish = (values) => {
    dispatch(loginStart());
    account
      .login(values)
      .then(({ result }) => {
        dispatch(loginSuccess(result));
        if (returnUrl) {
          history.push(returnUrl);
        } else {
          history.push(redirect);
        }
      })
      .finally(() => {
        dispatch(loginStop());
      });
  };

  return (
    <div className="main-wrapper login-page">
      <div className="login">
        <div className="con-box">
          <h4>关学文库专题知识库</h4>
          <div className="login-box">
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={handleFinish}
              autoComplete="off"
              noValidate
            >
              <Form.Item
                name="phoneNumber"
                rules={[{ required: true, message: "请输入手机号" }]}
              >
                <Input
                  disabled={logining}
                  className="login-input login-username"
                  placeholder="请输入手机号"
                />
              </Form.Item>
              <Form.Item
                name="codeOrPassword"
                rules={[{ required: true, message: "请输入密码" }]}
              >
                <Input
                  disabled={logining}
                  className="login-input login-password"
                  type="password"
                  placeholder="请输入密码"
                />
              </Form.Item>
              <Form.Item className="captcha-item">
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="checkCode"
                      noStyle
                      rules={[{ required: true, message: "请输入验证码!" }]}
                    >
                      <Input
                        disabled={logining}
                        className="login-input login-captcha"
                        placeholder="验证码"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <img src={`/checkcode?r=${random}`} alt="" />
                  </Col>
                  <Col span={5}>
                    <Button
                      disabled={logining}
                      onClick={updateRandom}
                      className="btn-captcha"
                    >
                      换一张
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Form.Item name="remeberMe" valuePropName="checked" noStyle>
                  <Checkbox className="login-chb">记住密码</Checkbox>
                </Form.Item>
                {/* <Link className="login-form-forgot" to="/account/forgotpassword">
                                    忘记密码？
                                </Link> */}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={logining}
                >
                  登 录
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
