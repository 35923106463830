import ArticlesBox from "./components/ArticlesBox";
import BookImageBox from "./components/BookImageBox";
import PeopleBox from "./components/PeopleTwoLineBox";
import SearchKeyBox from "@/components/SearchKeyBox";
import { List, Card, Image, Row, Col } from "antd";

import STMY from "@/assets/images/gxwksmty1.jpg";
import STTY from "@/assets/images/gxwksmfm.png";

import { Link } from "react-router-dom";

import "./index.css"

const HomePage = () => {
    return (
        <div className='main-wrapper home-page'>
            <SearchKeyBox />
            <div className="con-box">
                <Row gutter={30}>
                    <Col span={24}>
                        <div className="bkc-item">
                            <div style={{ width:"1400px",margin:"0 auto", marginTop: "30px",marginBottom:"-30px" }}>
                                <Link to="/smty" target="_blank">
                                    <img src={STMY} style={{ width: "100%" }} />
                                </Link>
                            </div>
                            <div style={{ width:"1400px",margin:"0 auto",border:" 1px solid #d3c8a8",paddingTop:"45px",paddingBottom:"30px" }}>
                                <div style={{textAlign:"center"}}>
                                    <Link to="/smty" target="_blank">
                                        <img class="gx-b-cover" src={STTY} style={{ height: "229px", width: "169px" }} />
                                    </Link>  
                                    <p class="ant-card-meta-title" style={{marginTop:"24px"}}>关学文库书目提要</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <BookImageBox />
        </div>
    )
};

export default HomePage;