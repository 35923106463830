import React from "react";
import { Link } from "react-router-dom";
import { message } from "antd";
import { useAuth, useCanReadArticle, useCanReadBook } from "../../hooks/account";

const AuthLink = (params) => {
  const auth = useAuth();
  const { to } = params;

  if (!auth) {
    return (
      <Link
        {...params}
        target="_self"
        to={{ pathname: "/login", state: { redirect: to || "/" } }}
      />
    );
  }

  return <Link {...params} />;
};

const preventSubscribeInvalidLink = (e, auth, canRead) => {
  if (!canRead) {
    e.preventDefault();

    if(!auth) {
      message.warn("请先登录");
    }else {
      message.warn("请联系管理员订阅数据");
    }
  }
};

//订阅有效链接
export const ArticleDataLink = (params) => {
  const auth = useAuth();
  const canRead = useCanReadArticle();
  return (
    <Link
      onClick={(e) => preventSubscribeInvalidLink(e, auth, canRead)}
      {...params}
    />
  );
};


export const BookDataLink = (params) => {
  const auth = useAuth();
  const canRead = useCanReadBook();
  return (
    <Link
      onClick={(e) => preventSubscribeInvalidLink(e, auth, canRead)}
      {...params}
    />
  );
};

export default AuthLink;
