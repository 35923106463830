import axios from "../../request";


const ADD_FAVORITE = 'profile/addfavorite';
const HAS_FAVORITE = 'profile/hasfavorite';
const REMOVE_FAVORITE = 'profile/removefavorite';
const GET_FAVORITE_BOOKS = 'profile/getfavoritebooks';
const GET_FAVORITE_CHAPTERS = 'profile/getfavoritechapters';
const GET_FAVORITE_ARTICLES = 'profile/getfavoritearticles';
const GET_SUBSCRIBES = 'profile/getsubscribes';



export const addFavorite = (params) => axios.post(ADD_FAVORITE, params);
export const removeFavorite = (params) => axios.post(REMOVE_FAVORITE, params);
export const hasFavorite = (params) => axios.post(HAS_FAVORITE, params);
export const getFavoriteBooks = (params) => axios.get(GET_FAVORITE_BOOKS, { data: params });
export const getFavoriteChapters = (params) => axios.get(GET_FAVORITE_CHAPTERS, { data: params });
export const getFavoriteArticles = (params) => axios.get(GET_FAVORITE_ARTICLES, { data: params });
export const getSubscribes = (params) => axios.get(GET_SUBSCRIBES, { data: params });