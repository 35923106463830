import { Row, Col } from "antd";

import AndroidQr from "@/assets/images/qrcode-android.png";
import IosQr from "@/assets/images/qrcode-iphone.png";
import { Link } from "react-router-dom";

import { useMirrorVersion } from "@/hooks/account"

const GlobalFooter = () => {

    const isMirror = useMirrorVersion();

    return (
        <footer id="footer">
            <div className="con-box">
                <Row>
                    <Col span={18}>
                        <div className="link">
                            {
                                !isMirror && <><Link to="/site/purchase">购买流程</Link>/</>
                            }
                            <Link to="/site/aboutus">关于我们</Link>/
                            <Link to="/site/contact">联系我们</Link>
                        </div>
                        <p>
                            <span>西北大学出版社</span>
                            <span>经营许可证编号 京ICP证 050760号</span>
                        </p>
                        <p>
                            <span>版权所有 侵权必究 盗版侵权</span>
                            <span>举报热线 400-168-0010</span>
                        </p>
                    </Col>
                    {/* <Col span={6}>
                        <div className="qrcode">
                            <img src={IosQr} alt="" />
                            <p>苹果下载</p>
                        </div>
                        <div className="qrcode">
                            <img src={AndroidQr} alt="" />
                            <p>安卓下载</p>
                        </div>
                    </Col> */}
                </Row>
            </div>
        </footer>
    );
};


export default GlobalFooter;