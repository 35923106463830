import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import GlobalFooter from "./components/footer";
import GlobalHeader from "./components/header";
import "./index.css";

const MainLayout = ({ children }) => {

    const { pathname } = useLocation();
    const [height, setHeight] = useState(390);

    const history = useHistory();

    useEffect(() => {
        if(pathname.startsWith('/articles') 
        || pathname.startsWith('/chapters')
        || pathname.startsWith('/books')
        || pathname.startsWith('/my')
        || pathname.startsWith('/site')) {
            setHeight(300)
        }
    }, [pathname]);

    useEffect(() => {
        return history.listen((route) => {
            message.destroy();
        });
    }, [history]);

    return (
        <div className="page-wrapper" style={{ height }}>
            <GlobalHeader />
            {children}
            <GlobalFooter />
        </div>
    );
};


export default MainLayout;