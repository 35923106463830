
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import SearchKeyBox from "../../components/SearchKeyBox";

import "./index.css";

const SearchLayout = ({ children }) => {
    return (
        <div className='main-wrapper search-page'>
            <SearchKeyBox />
            <div className="bread-crumb">
                <div className="con-box">
                    <Breadcrumb>
                        <Breadcrumb.Item>当前位置：</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={"/"}>首页</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>检索结果</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            {children}
        </div>
    );
};


export default SearchLayout;