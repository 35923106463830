import React from "react";
import AppRouters from "../routes";
import "./App.css";
import { loginSuccess } from "@/features/account";
import { useDispatch } from "react-redux";
import { account } from "@/services";
import { useAsync } from "react-use";
import PageLoading from "../components/PageLoading";

function App() {
  const dispatch = useDispatch();

  const { loading } = useAsync(
    () =>
      new Promise((resolve) => {
        return account.getCurrentUser().then(({ result }) => {
          dispatch(loginSuccess(result));
          resolve(result);
        });
      }),
    []
  );

  if (loading) {
    return <PageLoading />;
  }

  return <AppRouters />;
}

export default App;
