export const HOMEPAGE_TOPARTICLES_CATEGORY = ["研究资讯", "研究观点"];
export const HOMEPAGE_TOPPEOPLES_CATEGORY = ["古代人物", "现代人物"];
export const HOMEPAGE_BOOKS_CATEGORY = ["文献资料", "研究资料"];


export const SEARCH_OPTIONS = [
    { path: "all", category: "全文检索" },
    { path: "chapters", category: "文献资料" },
    { path: "chapters", category: "研究资料" },
    { path: "articles", category: "研究资讯" },
    { path: "articles", category: "研究观点" }
];


export const SEARCH_PATH = ["all", "articles", "chapters"];


export const IDENTITY_TOKEN_NAME = '.web.iden';


export const SITE_TITLE = "关学文库专题知识库";