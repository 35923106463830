import React from "react";
import { Spin } from "antd";

const PageLoading = (params) => {
    return (
        <div style={{ paddingTop: 100, textAlign: 'center' }}>
            <Spin spinning={true} size="large"  {...params}/>
        </div>
    );
};


export default PageLoading;
