import {  useSelector } from "react-redux";

export const useMirrorVersion = () => {
    return useAccountProperty("mirrorVersion");
}

export const useAuth = () => {
    const userId = useAccountId();
    return !!userId && userId > 0;
};

export const useAccountId = () => {
    return useAccountProperty("userId");
}

export const useAccountUserName = () => {
    return useAccountProperty("userName");
}


export const useAccountDisplayName = () => {
    const realName = useAccountProperty("realName");
    const userName = useAccountUserName();
    const userId = useAccountId();
    return realName || userName || `用户${userId}`;
}

export const useAccountProperty = (property) => {
    return useSelector(state => state.account[property]);
}


export const useCurrentAccount = () => {
    return useSelector(state => state.account);
};


export const useAccountSubscribed = () => {
    return useAccountProperty("subscribed");
}

//是否可以阅读文章
export const useCanReadArticle = () => {
    const articlePublic = useAccountProperty("articlePublic");
    const subscribed = useAccountProperty("subscribed");
    if(!articlePublic) {
        return subscribed;
    }else{
        return true;
    }
};

//是否可以阅读图书数据
export const useCanReadBook = () => {
    const bookPublic = useAccountProperty("bookPublic");
    const subscribed = useAccountProperty("subscribed");
    if(!bookPublic) {
        return subscribed;
    }else{
        return true;
    }
};