import { Redirect, useLocation } from "react-router";
import { useAuth } from "@/hooks/account";
import { Breadcrumb, Row, Col, Avatar, Image, Menu } from "antd";
import { Link } from "react-router-dom";
import { useCurrentAccount, useAccountDisplayName } from "@/hooks/account";
import "./index.css";

const UserCenterLayout = ({ children }) => {
    const auth = useAuth();
    const displayName = useAccountDisplayName();
    //const { avatorId } = useCurrentAccount();
    const { pathname } = useLocation();
    const index = ["articles", "chapters", "books"].findIndex(x => pathname.endsWith(x)) + '';

    if (!auth) {
        return <Redirect to="/login" />;
    }

    return (
        <div className='uc-page'>
            <div className="details-banner">
                <div className="con-box">
                    <h4>关学文库专题知识库</h4>
                </div>
            </div>

            <div className="bread-crumb">
                <div className="con-box">
                    <Breadcrumb>
                        <Breadcrumb.Item>当前位置：</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/'}>首页</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>个人中心</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="user-center">
                <div className="con-box">
                    <Row gutter={60}>
                        <Col span={6}>
                            <div className="user-info">
                                <Avatar
                                    src={
                                        <Image
                                            src={`/images/avator.png`}
                                            fallback='/images/nopic.jpg'
                                        />}
                                />
                                <p className="user-name">{displayName}</p>
                                <Link to="/my/profile/modify-password" className="modify-password">修改密码</Link>
                            </div>
                            <div className="user-nav">
                                <h3><span>个人中心导航</span></h3>
                                <h4>我的收藏管理</h4>
                                <Menu
                                    defaultSelectedKeys={[index]}
                                    mode="inline"
                                >
                                    <Menu.Item key="0">
                                        <i></i>
                                        <Link to="/my/collects/articles">研究资讯</Link>
                                        <span className="menu-arrow"></span>
                                    </Menu.Item>
                                    <Menu.Item key="1">
                                        <i></i>
                                        <Link to="/my/collects/chapters">章节</Link>
                                        <span className="menu-arrow"></span>
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                        <i></i>
                                        <Link to="/my/collects/books">图书</Link>
                                        <span className="menu-arrow"></span>
                                    </Menu.Item>
                                </Menu>
                            </div>
                        </Col>
                        <Col span={18}>
                            {children}
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};


export default UserCenterLayout;