import axios from "../../request";

const GET_CURRENT_LOGINED_USER = 'account/getcurrentlogineduser';
const RESET_PASSWORD = 'account/resetpassword';
const FORGOT_PASSWORD = 'account/forgotpassword';
const MODIFY_PASSWORD = 'account/modifypassword';
const LOGIN = 'account/login';
const LOGOUT = 'account/logout';


export const getCurrentUser = () => axios.post(GET_CURRENT_LOGINED_USER);
export const resetPassword = (params) => axios.post(RESET_PASSWORD, params);
export const forgotPassword = (params) => axios.post(FORGOT_PASSWORD, params);
export const modifyPassword = (params) => axios.post(MODIFY_PASSWORD, params);
export const login = (params) => axios.post(LOGIN, params);
export const logout = () => axios.post(LOGOUT);