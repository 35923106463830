import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userId: 0,
    userName: "",
    realName: "",
    avatorId: "",
    logining: false,
    subscribed: false,
    articlePublic: false,
    bookPublic: false,
    mirrorVersion: false
};

const slice = createSlice({
    name: "account",
    initialState,
    reducers: {
        loginStart: (state, action) => {
            state.logining = true;
            return state;
        },
        loginStop: (state, action) => {
            state.logining = false;
            return state;
        },
        loginSuccess: (state, action) => {
            state = action.payload;
            return state;
        },
        logout: (state, action) => {
            return initialState;
        },
        validMirrorSub: (state, action) => {
            return {
                ...state,
                subscribed: true
            };
        }
    }
});

const { reducer, actions } = slice;

export const { loginSuccess, logout, loginStart, loginStop, validMirrorSub } = actions;

export default reducer;