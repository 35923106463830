import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import zhCN from "antd/lib/locale/zh_CN";
import { store } from './store';
import App from './pages/App';
import { ConfigProvider } from 'antd';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
          <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
