import { Redirect, Route, Switch } from "react-router";
import React, { lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import MainLayout from "./layouts/main";
import UserCenterLayout from "./layouts/user";
import SearchLayout from "./layouts/search";
import HomePage from "./pages/home";
import LoginPage from "./pages/account/login";
import NoFoundPage from "./pages/404";
import PageLoading from "./components/PageLoading";
import { useMirrorVersion, useAccountSubscribed } from "./hooks/account";

const BlankLayout = ({ children }) => {
  //判断镜像版本
  const mirrorVersion = useMirrorVersion();
  const subscribed = useAccountSubscribed();


  if (mirrorVersion && !subscribed) {
    return <Redirect to="/system/regist" />;
  }

  return children;
};

/*
 * 路由定义
 */
const routes = [
  {
    path: "/system/regist",
    exact: true,
    component: lazy(() => import("./pages/license/regist")),
  },
  {
    path: "/",
    component: BlankLayout,
    routes: [
      {
        path: "/smty",
        exact: true,
        component: lazy(() => import("./pages/home/smty")),
      },
      {
        path: "/books/reader/:id",
        exact: true,
        component: lazy(() => import("./pages/books/reader")),
      },
      {
        path: "/books/editor/:id",
        exact: true,
        component: lazy(() => import("./pages/books/editor")),
      },
      {
        path: "/",
        component: MainLayout,
        routes: [
          {
            path: "/",
            exact: true,
            component: HomePage,
          },
          {
            path: "/login",
            exact: true,
            component: LoginPage,
          },
          {
            path: "/search",
            component: SearchLayout,
            routes: [
              {
                path: "/search/articles/:category?",
                exact: true,
                component: lazy(() => import("./pages/search/articles")),
              },
              {
                path: "/search/results",
                exact: true,
                component: lazy(() => import("./pages/search/results")),
              },
            ],
          },
          {
            path: "/articles/:id/:title?",
            component: lazy(() => import("./pages/articles/detail")),
          },
          {
            path: "/chapters/:id/:title?",
            component: lazy(() => import("./pages/chapters/detail")),
          },
          {
            path: "/my",
            component: UserCenterLayout,
            routes: [
              {
                path: "/my/profile/modify-password",
                exact: true,
                component: lazy(() =>
                  import("./pages/my/profile/modify/password")
                ),
              },
              {
                path: "/my/collects/articles",
                exact: true,
                component: lazy(() => import("./pages/my/collects/articles")),
              },
              {
                path: "/my/collects/chapters",
                exact: true,
                component: lazy(() => import("./pages/my/collects/chapters")),
              },
              {
                path: "/my/collects/books",
                exact: true,
                component: lazy(() => import("./pages/my/collects/books")),
              },
            ],
          },
          {
            path: "/books/detail/:id",
            exact: true,
            component: lazy(() => import("./pages/books/detail")),
          },
          {
            path: "/books/:category?",
            exact: true,
            component: lazy(() => import("./pages/books")),
          },
          {
            path: "/peoples/:category?",
            exact: true,
            component: lazy(() => import("./pages/peoples")),
          },
          {
            path: "/site",
            component: lazy(() => import("./pages/site")),
            routes: [
              {
                path: "/site/purchase",
                exact: true,
                component: lazy(() => import("./pages/site/purchase")),
              },
              {
                path: "/site/aboutus",
                exact: true,
                component: lazy(() => import("./pages/site/aboutus")),
              },
              {
                path: "/site/contact",
                exact: true,
                component: lazy(() => import("./pages/site/contact")),
              },
              {
                path: "/site/ad",
                exact: true,
                component: lazy(() => import("./pages/site/ad")),
              },
            ],
          },
          {
            path: "/page404",
            exact: true,
            component: NoFoundPage,
          },
          {
            path: "/**",
            exact: true,
            component: NoFoundPage,
          },
        ],
      },
    ],
  },
];

const AppRouters = () => {
  return (
    <Router>
      <React.Suspense fallback={<PageLoading />}>
        {renderRoutes(routes)}
      </React.Suspense>
    </Router>
  );
};

const renderRoutes = (routes = []) => {
  return (
    <Switch>
      {routes.map((route, i) => {
        const redirect = route.redirect;
        const Component = route.component;

        if (redirect) {
          return <Redirect to={redirect} />;
        }

        return (
          <Route
            key={i}
            path={route.path}
            exact={!!route.exact}
            component={() => (
              <Component
                children={route.routes && renderRoutes(route.routes)}
              />
            )}
          ></Route>
        );
      })}
    </Switch>
  );
};

export default AppRouters;
