import React from "react";
import { Image } from "antd";

//图书文内图片
export const BookContentImage = (params) => {
  const { bookId, type = 0, src, ...rest } = params;

  return src ? (
    type === 0 ? (
      <Image
        src={`/images/book/${bookId}/images/${src}`}
        fallback="/images/nopic.jpg"
        {...rest}
      />
    ) : (
      <img
        src={`/images/book/${bookId}/images/${src}`}
        style={{ width: "1em", height: "1em" }}
        alt="文内图"
      />
    )
  ) : (
    <Image src={`/images/nopic.jpg`} {...rest} />
  );
};

export const BookCoverImage = (params) => {
  const { bookId, coverId, ...rest } = params;

  return coverId ? (
    <Image
      src={`/images/book/${bookId}/covers/${coverId}`}
      fallback="/images/nopic.jpg"
      {...rest}
    />
  ) : (
    <Image src={`/images/nopic.jpg`} {...rest} />
  );
};



export const PeopleCoverImage = (params) => {
  const { coverId, ...rest } = params;

  return coverId ? (
    <Image
      fallback="/images/nopic.jpg"
      {...rest}
    />
  ) : (
    <Image src={`/images/nopic.jpg`} {...rest} />
  );
};
