import { Row, Col, Button } from "antd";

import Logo from "@/assets/images/logo.png";
import { useAccountDisplayName, useAuth } from "@/hooks/account";
import { Link, useHistory } from "react-router-dom";
import { account } from "@/services";
import { useDispatch } from "react-redux";
import { logout } from "@/features/account";
import { useMirrorVersion } from "@/hooks/account";

const GlobalHeader = () => {
  const auth = useAuth();
  const displayName = useAccountDisplayName();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMirror = useMirrorVersion();

  const handleLogout = () => {
    account
      .logout()
      .then(() => {
        dispatch(logout());
      })
      .finally(() => {
        history.push("/");
      });
  };

  return (
    <header id="header">
      <div className="con-box">
        <Row>
          <Col span={6}>
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </Col>
          { !isMirror && (
            <Col span={18}>
              {!auth ? (
                <Link to={"/login"}>
                  <Button type="primary">登录</Button>
                </Link>
              ) : (
                <Link to="/my/collects/articles">
                  <Button type="primary">您好：{displayName}</Button>
                </Link>
              )}
              {auth && (
                <Button onClick={handleLogout} type="primary">
                  退出
                </Button>
              )}
              <Link to={auth ? "/my/collects/articles" : "/login"}>
                <Button type="primary">我的收藏</Button>
              </Link>
            </Col>
          )}
        </Row>
      </div>
    </header>
  );
};

export default GlobalHeader;
