import { List, Card, Image, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { book } from "@/services";
import { HOMEPAGE_BOOKS_CATEGORY } from "@/consts";
import { BookCoverImage } from "../../../../components/BookImage";

const { Meta } = Card;

const [c1, c2] = HOMEPAGE_BOOKS_CATEGORY;

//图书栏
const BooksBox = () => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState({});

  useEffect(() => {
    setLoading(true);
    book
      .getTopBooks({ top: 6 })
      .then(({ result }) => {
        setResult(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const c1Items = result[c1] || [];
  const c2Items = result[c2] || [];

  return (
    <div className="con-item bkc">
      <div className="con-box">
        <Row gutter={30}>
          <Col span={24}>
            <div className="bkc-item">
              <h3>
                <span>文献整理系列书籍</span>
                <Link to={`/books/${c1}`}>查看更多</Link>
              </h3>
              <List
                itemLayout="horizontal"
                dataSource={c1Items.slice(0, 6)}
                grid={{ gutter: 16, column: 6 }}
                loading={loading}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <Card
                      bordered={false}
                      cover={
                        <Link to={`/books/detail/${item.id}`}>
                          <BookCoverImage
                            preview={false}
                            bookId={item.id}
                            coverId={item.coverId}
                            className="gx-b-cover"
                            style={{ width: 169, height: 229 }}
                          />
                        </Link>
                      }
                    >
                      <Meta
                        style={{ textAlign: "center" }}
                        title={
                          <Link to={`/books/detail/${item.id}`}>
                            {item.title}
                          </Link>
                        }
                      />
                    </Card>
                  </List.Item>
                )}
              ></List>
            </div>
          </Col>
        </Row>
      </div>
      <div className="con-box con-box1">
        <Row gutter={30}>
          <Col span={24}>
            <div className="bkc-item">
              <h3>
                <span>学术研究系列书籍</span>
                <Link to={`/books/${c2}`}>查看更多</Link>
              </h3>
              <List
                itemLayout="horizontal"
                dataSource={c2Items.slice(0, 6)}
                loading={loading}
                grid={{ gutter: 16, column: 6 }}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <Card
                      bordered={false}
                      cover={
                        <Link to={`/books/detail/${item.id}`}>
                          <BookCoverImage
                            preview={false}
                            bookId={item.id}
                            coverId={item.coverId}
                            className="gx-b-cover"
                            style={{ width: 169, height: 229 }}
                          />
                        </Link>
                      }
                    >
                      <Meta
                        style={{ textAlign: "center" }}
                        title={
                          <Link to={`/books/detail/${item.id}`}>
                            {item.title}
                          </Link>
                        }
                      />
                    </Card>
                  </List.Item>
                )}
              ></List>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    
  );
};

export default BooksBox;
