import { useLocation } from "react-router"

export const useSearchParams = () => {
    const { search } = useLocation();
    const result = {};

    if(search) {
        var params = new URLSearchParams(search);
        params.forEach((value, key) => {
            result[key] = value;
        })
    }

    return result;
}